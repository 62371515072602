var githubLightDefault = Object.freeze({
  "colors": {
    "activityBar.activeBorder": "#fd8c73",
    "activityBar.background": "#ffffff",
    "activityBar.border": "#d0d7de",
    "activityBar.foreground": "#1f2328",
    "activityBar.inactiveForeground": "#656d76",
    "activityBarBadge.background": "#0969da",
    "activityBarBadge.foreground": "#ffffff",
    "badge.background": "#0969da",
    "badge.foreground": "#ffffff",
    "breadcrumb.activeSelectionForeground": "#656d76",
    "breadcrumb.focusForeground": "#1f2328",
    "breadcrumb.foreground": "#656d76",
    "breadcrumbPicker.background": "#ffffff",
    "button.background": "#1f883d",
    "button.foreground": "#ffffff",
    "button.hoverBackground": "#1a7f37",
    "button.secondaryBackground": "#ebecf0",
    "button.secondaryForeground": "#24292f",
    "button.secondaryHoverBackground": "#f3f4f6",
    "checkbox.background": "#f6f8fa",
    "checkbox.border": "#d0d7de",
    "debugConsole.errorForeground": "#cf222e",
    "debugConsole.infoForeground": "#57606a",
    "debugConsole.sourceForeground": "#9a6700",
    "debugConsole.warningForeground": "#7d4e00",
    "debugConsoleInputIcon.foreground": "#6639ba",
    "debugIcon.breakpointForeground": "#cf222e",
    "debugTokenExpression.boolean": "#116329",
    "debugTokenExpression.error": "#a40e26",
    "debugTokenExpression.name": "#0550ae",
    "debugTokenExpression.number": "#116329",
    "debugTokenExpression.string": "#0a3069",
    "debugTokenExpression.value": "#0a3069",
    "debugToolBar.background": "#ffffff",
    "descriptionForeground": "#656d76",
    "diffEditor.insertedLineBackground": "#aceebb4d",
    "diffEditor.insertedTextBackground": "#6fdd8b80",
    "diffEditor.removedLineBackground": "#ffcecb4d",
    "diffEditor.removedTextBackground": "#ff818266",
    "dropdown.background": "#ffffff",
    "dropdown.border": "#d0d7de",
    "dropdown.foreground": "#1f2328",
    "dropdown.listBackground": "#ffffff",
    "editor.background": "#ffffff",
    "editor.findMatchBackground": "#bf8700",
    "editor.findMatchHighlightBackground": "#fae17d80",
    "editor.focusedStackFrameHighlightBackground": "#4ac26b66",
    "editor.foldBackground": "#6e77811a",
    "editor.foreground": "#1f2328",
    "editor.lineHighlightBackground": "#eaeef280",
    "editor.linkedEditingBackground": "#0969da12",
    "editor.selectionHighlightBackground": "#4ac26b40",
    "editor.stackFrameHighlightBackground": "#d4a72c66",
    "editor.wordHighlightBackground": "#eaeef280",
    "editor.wordHighlightBorder": "#afb8c199",
    "editor.wordHighlightStrongBackground": "#afb8c14d",
    "editor.wordHighlightStrongBorder": "#afb8c199",
    "editorBracketHighlight.foreground1": "#0969da",
    "editorBracketHighlight.foreground2": "#1a7f37",
    "editorBracketHighlight.foreground3": "#9a6700",
    "editorBracketHighlight.foreground4": "#cf222e",
    "editorBracketHighlight.foreground5": "#bf3989",
    "editorBracketHighlight.foreground6": "#8250df",
    "editorBracketHighlight.unexpectedBracket.foreground": "#656d76",
    "editorBracketMatch.background": "#4ac26b40",
    "editorBracketMatch.border": "#4ac26b99",
    "editorCursor.foreground": "#0969da",
    "editorGroup.border": "#d0d7de",
    "editorGroupHeader.tabsBackground": "#f6f8fa",
    "editorGroupHeader.tabsBorder": "#d0d7de",
    "editorGutter.addedBackground": "#4ac26b66",
    "editorGutter.deletedBackground": "#ff818266",
    "editorGutter.modifiedBackground": "#d4a72c66",
    "editorIndentGuide.activeBackground": "#1f23283d",
    "editorIndentGuide.background": "#1f23281f",
    "editorInlayHint.background": "#afb8c133",
    "editorInlayHint.foreground": "#656d76",
    "editorInlayHint.paramBackground": "#afb8c133",
    "editorInlayHint.paramForeground": "#656d76",
    "editorInlayHint.typeBackground": "#afb8c133",
    "editorInlayHint.typeForeground": "#656d76",
    "editorLineNumber.activeForeground": "#1f2328",
    "editorLineNumber.foreground": "#8c959f",
    "editorOverviewRuler.border": "#ffffff",
    "editorWhitespace.foreground": "#afb8c1",
    "editorWidget.background": "#ffffff",
    "errorForeground": "#cf222e",
    "focusBorder": "#0969da",
    "foreground": "#1f2328",
    "gitDecoration.addedResourceForeground": "#1a7f37",
    "gitDecoration.conflictingResourceForeground": "#bc4c00",
    "gitDecoration.deletedResourceForeground": "#cf222e",
    "gitDecoration.ignoredResourceForeground": "#6e7781",
    "gitDecoration.modifiedResourceForeground": "#9a6700",
    "gitDecoration.submoduleResourceForeground": "#656d76",
    "gitDecoration.untrackedResourceForeground": "#1a7f37",
    "icon.foreground": "#656d76",
    "input.background": "#ffffff",
    "input.border": "#d0d7de",
    "input.foreground": "#1f2328",
    "input.placeholderForeground": "#6e7781",
    "keybindingLabel.foreground": "#1f2328",
    "list.activeSelectionBackground": "#afb8c133",
    "list.activeSelectionForeground": "#1f2328",
    "list.focusBackground": "#ddf4ff",
    "list.focusForeground": "#1f2328",
    "list.highlightForeground": "#0969da",
    "list.hoverBackground": "#eaeef280",
    "list.hoverForeground": "#1f2328",
    "list.inactiveFocusBackground": "#ddf4ff",
    "list.inactiveSelectionBackground": "#afb8c133",
    "list.inactiveSelectionForeground": "#1f2328",
    "minimapSlider.activeBackground": "#8c959f47",
    "minimapSlider.background": "#8c959f33",
    "minimapSlider.hoverBackground": "#8c959f3d",
    "notificationCenterHeader.background": "#f6f8fa",
    "notificationCenterHeader.foreground": "#656d76",
    "notifications.background": "#ffffff",
    "notifications.border": "#d0d7de",
    "notifications.foreground": "#1f2328",
    "notificationsErrorIcon.foreground": "#cf222e",
    "notificationsInfoIcon.foreground": "#0969da",
    "notificationsWarningIcon.foreground": "#9a6700",
    "panel.background": "#f6f8fa",
    "panel.border": "#d0d7de",
    "panelInput.border": "#d0d7de",
    "panelTitle.activeBorder": "#fd8c73",
    "panelTitle.activeForeground": "#1f2328",
    "panelTitle.inactiveForeground": "#656d76",
    "pickerGroup.border": "#d0d7de",
    "pickerGroup.foreground": "#656d76",
    "progressBar.background": "#0969da",
    "quickInput.background": "#ffffff",
    "quickInput.foreground": "#1f2328",
    "scrollbar.shadow": "#6e778133",
    "scrollbarSlider.activeBackground": "#8c959f47",
    "scrollbarSlider.background": "#8c959f33",
    "scrollbarSlider.hoverBackground": "#8c959f3d",
    "settings.headerForeground": "#1f2328",
    "settings.modifiedItemIndicator": "#d4a72c66",
    "sideBar.background": "#f6f8fa",
    "sideBar.border": "#d0d7de",
    "sideBar.foreground": "#1f2328",
    "sideBarSectionHeader.background": "#f6f8fa",
    "sideBarSectionHeader.border": "#d0d7de",
    "sideBarSectionHeader.foreground": "#1f2328",
    "sideBarTitle.foreground": "#1f2328",
    "statusBar.background": "#ffffff",
    "statusBar.border": "#d0d7de",
    "statusBar.debuggingBackground": "#cf222e",
    "statusBar.debuggingForeground": "#ffffff",
    "statusBar.focusBorder": "#0969da80",
    "statusBar.foreground": "#656d76",
    "statusBar.noFolderBackground": "#ffffff",
    "statusBarItem.activeBackground": "#1f23281f",
    "statusBarItem.focusBorder": "#0969da",
    "statusBarItem.hoverBackground": "#1f232814",
    "statusBarItem.prominentBackground": "#afb8c133",
    "statusBarItem.remoteBackground": "#eaeef2",
    "statusBarItem.remoteForeground": "#1f2328",
    "symbolIcon.arrayForeground": "#953800",
    "symbolIcon.booleanForeground": "#0550ae",
    "symbolIcon.classForeground": "#953800",
    "symbolIcon.colorForeground": "#0a3069",
    "symbolIcon.constantForeground": "#116329",
    "symbolIcon.constructorForeground": "#3e1f79",
    "symbolIcon.enumeratorForeground": "#953800",
    "symbolIcon.enumeratorMemberForeground": "#0550ae",
    "symbolIcon.eventForeground": "#57606a",
    "symbolIcon.fieldForeground": "#953800",
    "symbolIcon.fileForeground": "#7d4e00",
    "symbolIcon.folderForeground": "#7d4e00",
    "symbolIcon.functionForeground": "#6639ba",
    "symbolIcon.interfaceForeground": "#953800",
    "symbolIcon.keyForeground": "#0550ae",
    "symbolIcon.keywordForeground": "#a40e26",
    "symbolIcon.methodForeground": "#6639ba",
    "symbolIcon.moduleForeground": "#a40e26",
    "symbolIcon.namespaceForeground": "#a40e26",
    "symbolIcon.nullForeground": "#0550ae",
    "symbolIcon.numberForeground": "#116329",
    "symbolIcon.objectForeground": "#953800",
    "symbolIcon.operatorForeground": "#0a3069",
    "symbolIcon.packageForeground": "#953800",
    "symbolIcon.propertyForeground": "#953800",
    "symbolIcon.referenceForeground": "#0550ae",
    "symbolIcon.snippetForeground": "#0550ae",
    "symbolIcon.stringForeground": "#0a3069",
    "symbolIcon.structForeground": "#953800",
    "symbolIcon.textForeground": "#0a3069",
    "symbolIcon.typeParameterForeground": "#0a3069",
    "symbolIcon.unitForeground": "#0550ae",
    "symbolIcon.variableForeground": "#953800",
    "tab.activeBackground": "#ffffff",
    "tab.activeBorder": "#ffffff",
    "tab.activeBorderTop": "#fd8c73",
    "tab.activeForeground": "#1f2328",
    "tab.border": "#d0d7de",
    "tab.hoverBackground": "#ffffff",
    "tab.inactiveBackground": "#f6f8fa",
    "tab.inactiveForeground": "#656d76",
    "tab.unfocusedActiveBorder": "#ffffff",
    "tab.unfocusedActiveBorderTop": "#d0d7de",
    "tab.unfocusedHoverBackground": "#eaeef280",
    "terminal.ansiBlack": "#24292f",
    "terminal.ansiBlue": "#0969da",
    "terminal.ansiBrightBlack": "#57606a",
    "terminal.ansiBrightBlue": "#218bff",
    "terminal.ansiBrightCyan": "#3192aa",
    "terminal.ansiBrightGreen": "#1a7f37",
    "terminal.ansiBrightMagenta": "#a475f9",
    "terminal.ansiBrightRed": "#a40e26",
    "terminal.ansiBrightWhite": "#8c959f",
    "terminal.ansiBrightYellow": "#633c01",
    "terminal.ansiCyan": "#1b7c83",
    "terminal.ansiGreen": "#116329",
    "terminal.ansiMagenta": "#8250df",
    "terminal.ansiRed": "#cf222e",
    "terminal.ansiWhite": "#6e7781",
    "terminal.ansiYellow": "#4d2d00",
    "terminal.foreground": "#1f2328",
    "textBlockQuote.background": "#f6f8fa",
    "textBlockQuote.border": "#d0d7de",
    "textCodeBlock.background": "#afb8c133",
    "textLink.activeForeground": "#0969da",
    "textLink.foreground": "#0969da",
    "textPreformat.foreground": "#656d76",
    "textSeparator.foreground": "#d8dee4",
    "titleBar.activeBackground": "#ffffff",
    "titleBar.activeForeground": "#656d76",
    "titleBar.border": "#d0d7de",
    "titleBar.inactiveBackground": "#f6f8fa",
    "titleBar.inactiveForeground": "#656d76",
    "tree.indentGuidesStroke": "#d8dee4",
    "welcomePage.buttonBackground": "#f6f8fa",
    "welcomePage.buttonHoverBackground": "#f3f4f6"
  },
  "displayName": "GitHub Light Default",
  "name": "github-light-default",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "scope": [
        "comment",
        "punctuation.definition.comment",
        "string.comment"
      ],
      "settings": {
        "foreground": "#6e7781"
      }
    },
    {
      "scope": [
        "constant.other.placeholder",
        "constant.character"
      ],
      "settings": {
        "foreground": "#cf222e"
      }
    },
    {
      "scope": [
        "constant",
        "entity.name.constant",
        "variable.other.constant",
        "variable.other.enummember",
        "variable.language",
        "entity"
      ],
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": [
        "entity.name",
        "meta.export.default",
        "meta.definition.variable"
      ],
      "settings": {
        "foreground": "#953800"
      }
    },
    {
      "scope": [
        "variable.parameter.function",
        "meta.jsx.children",
        "meta.block",
        "meta.tag.attributes",
        "entity.name.constant",
        "meta.object.member",
        "meta.embedded.expression"
      ],
      "settings": {
        "foreground": "#1f2328"
      }
    },
    {
      "scope": "entity.name.function",
      "settings": {
        "foreground": "#8250df"
      }
    },
    {
      "scope": [
        "entity.name.tag",
        "support.class.component"
      ],
      "settings": {
        "foreground": "#116329"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#cf222e"
      }
    },
    {
      "scope": [
        "storage",
        "storage.type"
      ],
      "settings": {
        "foreground": "#cf222e"
      }
    },
    {
      "scope": [
        "storage.modifier.package",
        "storage.modifier.import",
        "storage.type.java"
      ],
      "settings": {
        "foreground": "#1f2328"
      }
    },
    {
      "scope": [
        "string",
        "string punctuation.section.embedded source"
      ],
      "settings": {
        "foreground": "#0a3069"
      }
    },
    {
      "scope": "support",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": "meta.property-name",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": "variable",
      "settings": {
        "foreground": "#953800"
      }
    },
    {
      "scope": "variable.other",
      "settings": {
        "foreground": "#1f2328"
      }
    },
    {
      "scope": "invalid.broken",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#82071e"
      }
    },
    {
      "scope": "invalid.deprecated",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#82071e"
      }
    },
    {
      "scope": "invalid.illegal",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#82071e"
      }
    },
    {
      "scope": "invalid.unimplemented",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#82071e"
      }
    },
    {
      "scope": "carriage-return",
      "settings": {
        "background": "#cf222e",
        "content": "^M",
        "fontStyle": "italic underline",
        "foreground": "#f6f8fa"
      }
    },
    {
      "scope": "message.error",
      "settings": {
        "foreground": "#82071e"
      }
    },
    {
      "scope": "string variable",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": [
        "source.regexp",
        "string.regexp"
      ],
      "settings": {
        "foreground": "#0a3069"
      }
    },
    {
      "scope": [
        "string.regexp.character-class",
        "string.regexp constant.character.escape",
        "string.regexp source.ruby.embedded",
        "string.regexp string.regexp.arbitrary-repitition"
      ],
      "settings": {
        "foreground": "#0a3069"
      }
    },
    {
      "scope": "string.regexp constant.character.escape",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#116329"
      }
    },
    {
      "scope": "support.constant",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": "support.variable",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": "support.type.property-name.json",
      "settings": {
        "foreground": "#116329"
      }
    },
    {
      "scope": "meta.module-reference",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": "punctuation.definition.list.begin.markdown",
      "settings": {
        "foreground": "#953800"
      }
    },
    {
      "scope": [
        "markup.heading",
        "markup.heading entity.name"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#0550ae"
      }
    },
    {
      "scope": "markup.quote",
      "settings": {
        "foreground": "#116329"
      }
    },
    {
      "scope": "markup.italic",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#1f2328"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#1f2328"
      }
    },
    {
      "scope": [
        "markup.underline"
      ],
      "settings": {
        "fontStyle": "underline"
      }
    },
    {
      "scope": [
        "markup.strikethrough"
      ],
      "settings": {
        "fontStyle": "strikethrough"
      }
    },
    {
      "scope": "markup.inline.raw",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": [
        "markup.deleted",
        "meta.diff.header.from-file",
        "punctuation.definition.deleted"
      ],
      "settings": {
        "background": "#ffebe9",
        "foreground": "#82071e"
      }
    },
    {
      "scope": [
        "punctuation.section.embedded"
      ],
      "settings": {
        "foreground": "#cf222e"
      }
    },
    {
      "scope": [
        "markup.inserted",
        "meta.diff.header.to-file",
        "punctuation.definition.inserted"
      ],
      "settings": {
        "background": "#dafbe1",
        "foreground": "#116329"
      }
    },
    {
      "scope": [
        "markup.changed",
        "punctuation.definition.changed"
      ],
      "settings": {
        "background": "#ffd8b5",
        "foreground": "#953800"
      }
    },
    {
      "scope": [
        "markup.ignored",
        "markup.untracked"
      ],
      "settings": {
        "background": "#0550ae",
        "foreground": "#eaeef2"
      }
    },
    {
      "scope": "meta.diff.range",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#8250df"
      }
    },
    {
      "scope": "meta.diff.header",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": "meta.separator",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#0550ae"
      }
    },
    {
      "scope": "meta.output",
      "settings": {
        "foreground": "#0550ae"
      }
    },
    {
      "scope": [
        "brackethighlighter.tag",
        "brackethighlighter.curly",
        "brackethighlighter.round",
        "brackethighlighter.square",
        "brackethighlighter.angle",
        "brackethighlighter.quote"
      ],
      "settings": {
        "foreground": "#57606a"
      }
    },
    {
      "scope": "brackethighlighter.unmatched",
      "settings": {
        "foreground": "#82071e"
      }
    },
    {
      "scope": [
        "constant.other.reference.link",
        "string.other.link"
      ],
      "settings": {
        "foreground": "#0a3069"
      }
    }
  ],
  "type": "light"
});

export { githubLightDefault as default };
